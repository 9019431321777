import React from 'react'

function HelpSupport() {
  return (
    <div className='help-support'>
        <div className="help-support-heading">
            <h2>Send Message to Admin</h2>
        </div>
        <div className="selp-support-form">
            <form>
                <input type='text' placeholder='Your Name'/>
                <input type='text' placeholder='Your Phone'/>
                <input type='text' placeholder='Your Email'/>
                <textarea placeholder='Send Message.....' />
                <button type='button'>Send</button>
            </form>
        </div>
    </div>
  )
}

export default HelpSupport
