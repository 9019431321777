import React from 'react'
import image from '../../../../media/images/Rectangle 18236.png'
import master from '../../../../media/images/master.png';
import paynieer from '../../../../media/images/poynier.png';
import square from '../../../../media/images/square.png';
import paypal from '../../../../media/images/paypal.png';
import stripe from '../../../../media/images/stripe.png';
import methew from '../../../../media/images/customerIcon.png';
import chat from '../../../../media/images/customerChat.png';
import email from '../../../../media/images/ic_outline-email.png';
import location from '../../../../media/images/location.png';
function ServiceDetailsMain() {
    return (
        <div className='service-detail-wrapper-dashboard-side'>
            <div className="service-detail-service-dashboard-heading">
                <h2>Service Details</h2>
                <h3>Banner Printing</h3>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="service-package-image">
                        <img src={image} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="service-package-details">
                        <div className="service-package-details-wrapper">
                            <div className="service-package-subtotal-wrapper">
                                <h3>Subtotal ( 1 Service )</h3>
                                <p>$58.88</p>
                            </div>
                            {/* <div className="service-package-service-charge-wrapper"> */}
                            <div className="service-package-subtotal-wrapper">
                                <h3>Service Charge</h3>
                                <p>$10.00</p>
                            </div>
                            <div className="service-package-subtotal-wrapper">
                                <h3>Discount</h3>
                                <p>-$5.00</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='service-package-order-total'>
                            <h3>Order Total</h3>
                            <p>$ 63.88</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div class="payment-label" style={{ padding: "12px" }}>
                        <label>
                            <input type="radio" name="payment" id="credit-debit-card" />
                            <span>Credits and Debits Card</span>
                            <img src={paypal} alt="Visa" />
                            <img src={master} alt="MasterCard" />
                            <img src={square} alt="MasterCard" />
                            <img src={paynieer} alt="MasterCard" />
                            <img src={paypal} alt="PayPal" />
                        </label>
                    </div>
                </div>

            </div>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="service-package-delivery-address">
                        <div className="delivery-status-content">
                            <h3>Delivery Status</h3>
                            <p>Est Delivery: Tue, Dec 15 -Wed, Dec 16</p>
                        </div>
                        <div className="delivery-status-button">
                            <button>Pending</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6">
                    <div className="customer-detail main">
                        <div className="customer-detail-heading">
                            <h3>Customer Detail</h3>
                        </div>
                        <div className="customer-detail-wrapper">
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={methew} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <h4>Zaire Herwitz</h4>
                                    <p>#w34008</p>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={email} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <p>Adam@gmail.com </p>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={location} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <p>123 West 45th Street, New York, NY 10036</p>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={chat} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <p>Chat with Mathew</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                <div className="customer-detail main">
                        <div className="customer-detail-heading">
                            <h3>My Detail</h3>
                        </div>
                        <div className="customer-detail-wrapper">
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={methew} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <h4>Zaire Herwitz</h4>
                                    <p>#w34008</p>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={email} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <p>Adam@gmail.com </p>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={location} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <p>123 West 45th Street, New York, NY 10036</p>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-2">
                                    <div className="customer-detail-icon">
                                        <img src={chat} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                <div className="customer-detail-content">
                                    <p>Chat with Mathew</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceDetailsMain
