import React from 'react'
import AboutMethew from '../../../../screens/Service Flow/Service Sell Detail page/AboutMethew'
import GetToKnowAbtMethew from '../../../../screens/Service Flow/Service Sell Detail page/GetToKnowAbtMethew'
import OtherRecomendationsForSeller from '../../../../screens/Service Flow/Service Sell Detail page/OtherRecomendationsForSeller'
import Footer from '../../../../components/Layout/Footer'
import HeaderTop from '../../../../components/Layout/HeaderTop'
import PricePreviewTop from './PricePreviewTop'
import PackagesSection from './PackagesSection'
import SingleServiceFaqs from '../../../../screens/Service Flow/Single Service Detail page/SingleServiceFaqs'

function PreviewFixedPrice2() {
  return (
    <div>
       <HeaderTop/>
        <PricePreviewTop/>
        <PackagesSection/>
        <AboutMethew/>
        <GetToKnowAbtMethew/>
        <SingleServiceFaqs />
        <OtherRecomendationsForSeller/>
        <Footer/>
    </div>
  )
}

export default PreviewFixedPrice2
