import React, { useState } from 'react'
import ChatList from './ChatList'
import ChatView from './ChatView'

function Chat() {
    const [selectedChat, setSelectedChat] = useState(1);


  return (
    <div style={{ display: "flex", height: "75vh", background: "#fff" }}>
    <ChatList setSelectedChat={setSelectedChat} />
    <ChatView selectedChat={selectedChat} />
  </div>
  )
}

export default Chat
