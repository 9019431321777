import './media/style/style.css'
import './media/style/style2.css'
import SingUp from './screens/SingUp';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './screens/Login';
import ForgotPassword from './screens/ForgotPassword';
import Opt from './screens/Opt';
import NewPassword from './screens/NewPassword';
import ProductListing from './screens/ProductListing';
import OrganizationListing from './screens/OrganizationListing';
import ServiceListing from './screens/ServiceListing';
import Home from './screens/Home/Home';
import ProductToExploreDetail from './screens/ProductToExploreDetail';
import TopRatedStoresDetailPage from './screens/TopRatedStoresDetailPage';
import ServiceDetailPage from './screens/ServiceDetailPage';
import DonationDetailPage from './screens/DonationDetailPage';
import Service from './screens/Service Flow/Service';
import FeatureProduct from './screens/Feature Product/FeatureProduct';
import ProductDetail from './screens/Feature Product/ProductDetail/ProductDetail';
import ShoppingCart from './screens/AddToCartFlowForProduct/ShoppingCart';
import CheckOut from './screens/Checkout Flow/CheckOut';
import OrderTracking from './screens/Order Tracking/OrderTracking';
import AddBillingDetails from './screens/AddBillingDetails/AddBillingDetails';
import PopularService from './screens/Service Flow/PopularServiceFlow/PopularService';
import ServiceProvider from './screens/Service Flow/ServiceProviderFlow/ServiceProvider';
import ServiceCatagoryDetailPage from './screens/Service Flow/ServiceCatagoryDetailPage/ServiceCatagoryDetailPage';
import ServiceBillingPage from './screens/Service Flow/ServiceBillingPage';
import ServiceBillingDetailPage from './screens/Service Flow/ServiceBillingDetailPage';
import SelectYourDomain from './screens/SelectYourDomain';
import RegisterYourEcommerceStore from './screens/RegisterYourEcommerceStore';
import EmailVerfication from './components/Main/EmailVerfication';
import PackagesBecomeSeller from './screens/PackagesBecomeSeller';
import BuyPackage from './screens/BuyPackage';
import FundingCycle from './screens/Donations Flow/Donation Funding Cycle/FundingCycle';
import DonationEvent from './screens/Donations Flow/Donations Recent Event/DonationEvent';
import SingleServiceDetail from './screens/Service Flow/Single Service Detail page/SingleServiceDetail';
import SuplierStoreDetailPAge from './screens/Feature Product/SuplierStore/SuplierStoreDetailPage';
import SuplierStoreProductPage from './screens/Feature Product/Suplier Store Product/SuplierStoreProductPage';
import DonationHome from './screens/DonationsHomeFlow/DonationHome';
import ServiceSellProfile from './screens/Service Flow/Service Sell Detail page/ServiceSellProfile';
import ConfirmAppointment from './screens/Service Flow/Confirm Appointment/ConfirmAppointment';

import DonationNow from './screens/Donations Flow/Donation Now/DonationNow';
import DonationBeachClean from './screens/Donations Flow/Donation beach clean/DonationBeachClean';
import DonateNowForm from './screens/Donations Flow/Donate Now Form/DonateNowForm';
import BecomeVolunteer from './screens/Donations Flow/Become a Volunteer/BecomeVolunteer';
import DonationFoodDrive from './screens/Donations Flow/Donation Food Drive/DonationFoodDrive';
import DonationFundingCycleAll from './screens/Donations Flow/DonationFundingCycleAll/DonationFundingCycleAll';
import Nonprofit from './dashboards/Seller/Donation/NonProfitScreen/Nonprofit';
import NonProfitForm from './dashboards/Seller/Donation/NonProfitScreen/NonProfitForm';
import BuymonthlySubcribtion from './dashboards/Seller/Donation/BuyMonthlySub/BuymonthlySubcribtion';
import MealNeighbaForm from './dashboards/Seller/Donation/MealstoNeighbors/MealNeighbaForm';
import PreviewFixedPrice from './dashboards/Seller/Service/FrequetQuestionsAdd/PreviewFixedPrice';
import PreviewFixedPrice2 from './dashboards/Seller/Service/FrequetQuestionsAdd/PreviewFixedPrice2';
import ServiceSelerProfileTwo from './screens/Service Flow/Service Sell Detail page/ServiceSelerProfileTwo';
import VendorProfilePage from './screens/Service Flow/Service Sell Detail page/VendorProfilePage';

import ServiceDashboardSectionTwo from './dashboards/Seller/Service/ServiceMainPage/ServiceDashboardSectionTwo';
import StepFormDashboard from './dashboards/Seller/Service/SetupYourOffice/StepFormDashboard';
import ServiceDashboardTab from './dashboards/Seller/Service/ServiceMainPage/ServiceDashboardTab';
import AddServiceMainPage from './dashboards/Seller/Service/AddService/AddServiceMainPage';
import AddQuestionsMainPage from './dashboards/Seller/Service/FrequetQuestionsAdd/AddQuestionsMainPage';
import MainDashboardSeectionTwo from './dashboards/Seller/Service/MainDashboard/MainDashboardSeectionTwo';
import CompleteServiceSectionTwo from './dashboards/Seller/Service/Completed Services Graph/CompleteServiceSectionTwo';
import ServiceDetailsMain from './dashboards/Seller/Service/Table Dashboard Service Detail page/ServiceDetailsMain';
import SettingsServiceSection from './dashboards/Seller/Service/Settings -Service/SettingsServiceSection';
import NOtificationsSettingSectionMain from './dashboards/Seller/Service/Notifications Settings/NOtificationsSettingSectionMain';
import RevenueSectionTwo from './dashboards/Seller/Service/Total Revenu/RevenueSectionTwo';
import AppearanceSetting from './dashboards/Seller/Service/Appearance setting/AppearanceSetting';
import Chat from './dashboards/Seller/Service/Chat/Chat';
import HelpSupport from './dashboards/Seller/Service/Help and report/HelpSupport';
import Promotion from './dashboards/Seller/Service/Offer and Promotions/Promotion';

import ConnectBankService from './dashboards/Seller/Service/Wallet/ConnectBankService';
import WalletDetails from './dashboards/Seller/Service/Wallet/WalletDetails';
import WalletDetailAmountBalance from './dashboards/Seller/Service/Wallet/WalletDetailAmountBalance';
import ProductManagementLayout from './dashboards/Seller/Products/ProductDashboard/ProductManagementLayout';
import ProductDashboardStartHere from './dashboards/Seller/Products/productDashboardStart/ProductDashboardStartHere';
import SetupYourOffice from './dashboards/Seller/Products/Setup you office Product/SetupYourOffice';
import AddProduct from './dashboards/Seller/Products/AddProduct/AddProduct';
import ProductDashboardScreen from './dashboards/Seller/Products/MainProductDashboard/ProductDashboardScreen';
import CompleteProductSection from './dashboards/Seller/Products/CompleteProductSection/CompleteProductSection';
import TotalRevenueProduct from './dashboards/Seller/Products/TotalRevenueProduct/TotalRevenueProduct';
import ProductTableDashboardDetails from './dashboards/Seller/Products/ProductTableDashboardDetails/ProductTableDashboardDetails';
import ProductBankConnect from './dashboards/Seller/Products/ProductWallet/ProductBankConnect';
import ProductWalletDetails from './dashboards/Seller/Products/ProductWallet/ProductWalletDetails';
import WalletDetailAmountBalanceProduct from './dashboards/Seller/Products/ProductWallet/WalletDetailAmountBalanceProduct';
import ProductChat from './dashboards/Seller/Products/ProductChat/ProductChat';
import ProductPreviewDetailPage from './screens/Feature Product/ProductDetail/ProductPreviewDetailPage';
import VolunteerForm from './dashboards/Seller/Donation/VolunteerForm/VolunteerForm';
import DonationDashboardLayout from './dashboards/Seller/Donation/DonationDashboardLayou/DonationDashboardLayout';
import DonationDashboardStartHere from './dashboards/Seller/Donation/DonationDashboardStartHere/DonationDashboardStartHere';
import SetupYourOfficeDonations from './dashboards/Seller/Donation/Setup you office Donation/SetupYourOffice';
import MealFormSec from './dashboards/Seller/Donation/MealstoNeighbors/MealFormSec';
import BuyCampaignSubcribtion from './dashboards/Seller/Donation/BuycampaignSubs/BuycampaignSubs';
import EmailVerificationDonation from './dashboards/Seller/Donation/Email Verification/EmailVerificationDonation';
import DonationDashSectionOne from './dashboards/Seller/Donation/MainDonationDashboard/DonationDashSectionOne';
import CampaignDonationMainSectionOne from './dashboards/Seller/Donation/CampaignDonation/CampaignDonationMainSectionOne';
import CompleteDonationSection from './dashboards/Seller/Products/CompleteProductSection/CompleteProductSection';
import TotalRevenueDonation from './dashboards/Seller/Donation/TotalRevenueDonation/TotalRevenueDonation';
import DonationBankConnect from './dashboards/Seller/Donation/ProductWallet/DonationBankConnect';
import DonationWalletDetails from './dashboards/Seller/Donation/ProductWallet/DonationWalletDetails';
import WalletDetailAmountBalanceDonation from './dashboards/Seller/Donation/ProductWallet/WalletDetailAmountBalanceDonation';
import DonationEventSectionFirst from './dashboards/Seller/Donation/DonationEvent/DonationEventSectionFirst';
import DonationDashAddEventSectionFirst from './dashboards/Seller/Donation/DonationEvent/DonationDashAddEventSectionFirst';
import VolunteerFormSectionFirst from './dashboards/Seller/Donation/VolunteerForm/VolunteerFormSectionFirst';
import VolunteerProfileEditSectionFirst from './dashboards/Seller/Donation/VolunteerProfileEdit/VolunteerProfileEditSectionFirst';
import CustomerDashboardDashboard from './dashboards/Customer/Customer dashboard Layout/CustomerDashboardDashboard';
import ResetPasswordOtp from './screens/ResetPasswordOtp';






function App() {
  return (
    // commenttt
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />

          {/* Login Registration */}
          <Route path="/register" element={<SingUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/opt" element={<Opt />} />
          <Route path="/reset-password-otp" element={<ResetPasswordOtp />} />
          <Route path="/newpassword" element={<NewPassword />} />

          {/* Home Routes */}
          <Route path="/bussiness-product" element={<ProductListing />} />
          <Route path="/bussiness-service" element={<ServiceListing />} />
          <Route path="/bussiness-organization" element={<OrganizationListing />} />
          <Route path="/product-detail" element={<ProductToExploreDetail />} />
          <Route path="/store-detail" element={<TopRatedStoresDetailPage />} />
          <Route path="/service-detail" element={<ServiceDetailPage />} />
          <Route path="/donation-detail" element={<DonationDetailPage />} />

          {/* service flow */}
          <Route path="/service" element={<Service />} />
          <Route path="/popularService" element={<PopularService />} />
          <Route path="/serviceProvider" element={<ServiceProvider />} />
          <Route path="/serviceCatagory" element={<ServiceCatagoryDetailPage />} />
          <Route path="/serviceBilling" element={<ServiceBillingPage />} />
          <Route path="/serviceBillingDetail" element={<ServiceBillingDetailPage />} />
          <Route path="/singleServiceDetail" element={<SingleServiceDetail />} />
          <Route path="/Service-Seller-Profile" element={<ServiceSellProfile />} />
          <Route path="/Service-Seller-Profile-two" element={<ServiceSelerProfileTwo />} />
          <Route path="/confirmAppointment" element={<ConfirmAppointment />} />
          <Route path="/vandor-profile" element={<VendorProfilePage />} />


          {/* Feature Product Flow */}
          <Route path="/featureProduct" element={<FeatureProduct />} />
          <Route path="/productDetail" element={<ProductDetail />} />
          <Route path="/ShoppingCart" element={<ShoppingCart />} />
          <Route path="/CheckOut" element={<CheckOut />} />
          <Route path="/orderTracking" element={<OrderTracking />} />
          <Route path="/addBillingDetails" element={<AddBillingDetails />} />
          <Route path="/SuplierStore" element={<SuplierStoreDetailPAge />} />
          <Route path="/SuplierStoreProduct" element={<SuplierStoreProductPage />} />

          {/* Donations */}

          <Route path="/donation-now" element={<DonationNow />} />
          <Route path="/funding-cycle" element={<FundingCycle />} />
          <Route path="/funding-cycle-all" element={<DonationFundingCycleAll />} />
          <Route path="/donation-event" element={<DonationEvent />} />
          <Route path="/donation-event" element={<DonationEvent />} />
          <Route path="/donation-beach-clean" element={<DonationBeachClean />} />
          <Route path="/donate-form" element={<DonateNowForm />} />
          <Route path="/become-a-volunteer" element={<BecomeVolunteer />} />
          <Route path="/donation-food-drive" element={<DonationFoodDrive />} />
          <Route path="/donation-event" element={<DonationEvent />} />
          <Route path="/funding-cycle" element={<FundingCycle />} />



          {/* Become a seller Flow */}
          <Route path="/become-seller" element={<DonationHome />} />
          <Route path="/select-your-domain" element={<SelectYourDomain />} />
          <Route path="/register-your-ecommerce-store" element={<RegisterYourEcommerceStore />} />
          <Route path="/email-verfication" element={<EmailVerfication />} />
          <Route path="/packages-become-seller" element={<PackagesBecomeSeller />} />
          <Route path="/buy-package" element={<BuyPackage />} />
          <Route path="/select-your-domain" element={<SelectYourDomain />} />
          <Route path="/register-your-ecommerce-store" element={<RegisterYourEcommerceStore />} />
          <Route path="/email-verfication" element={<EmailVerfication />} />
          <Route path="/packages-become-seller" element={<PackagesBecomeSeller />} />
          <Route path="/buy-package" element={<BuyPackage />} />



          {/*  All  Dashboards  routes start from here */}



          {/* (Seller-Side)
               Service Dashboard  (Seller-Side) */}


          <Route path="/dashboard" element={<ServiceDashboardSectionTwo />}>
            {/* Child routes for dynamic content */}
            <Route path="service-dashboard" element={<ServiceDashboardTab />} />
            <Route path="setup-your-office" element={<StepFormDashboard />} />
            <Route path="add-service" element={<AddServiceMainPage />} />
            <Route path="add-service-details" element={<AddQuestionsMainPage />} />
            <Route path="seller-dashboard" element={<MainDashboardSeectionTwo />} />
            <Route path="complete-service-detail" element={<CompleteServiceSectionTwo />} />
            <Route path="total-revenue-detail" element={<RevenueSectionTwo />} />
            <Route path="dashboard-service-detail" element={<ServiceDetailsMain />} />
            <Route path="dashboard-service-settings" element={<SettingsServiceSection />} />
            <Route path="service-notifications" element={<NOtificationsSettingSectionMain />} />
            <Route path="service-appearance" element={<AppearanceSetting />} />
            <Route path="support" element={<HelpSupport />} />
            <Route path="promotions" element={<Promotion />} />
            <Route path="chat" element={<Chat />} />
            <Route path="bank_connect" element={<ConnectBankService />} />
            <Route path="wallet-description-service" element={<WalletDetails />} />
            <Route path="amount-balance-wallet-service" element={<WalletDetailAmountBalance />} />
          </Route>

          <Route path="/preview-details" element={<PreviewFixedPrice />} />
          <Route path="/preview-details2" element={<PreviewFixedPrice2 />} />
          
          
          {/* End ---------------- Service Dashboard  (Seller-Side) 


           Product Dashboard  (Seller-Side) */}
        
        <Route path="/product-dashboard" element={<ProductManagementLayout />}>
            {/* Child routes for dynamic content */}
            <Route path="product-dashboard-start" element={<ProductDashboardStartHere />} />
            <Route path="setup-your-office-product" element={<SetupYourOffice />} />
            <Route path="add-product" element={<AddProduct />} />
            <Route path="product-dashboard-screen" element={<ProductDashboardScreen />} />
            <Route path="complete-product-detail" element={<CompleteProductSection />} />
            <Route path="total-produts-revenue-detail" element={<TotalRevenueProduct />} />
            <Route path="dashboard-product-detail" element={<ProductTableDashboardDetails />} />
            <Route path="product-bank-connect" element={<ProductBankConnect />} />
            <Route path="wallet-description-product" element={<ProductWalletDetails />} />
            <Route path="amount-balance-wallet-product" element={<WalletDetailAmountBalanceProduct />} />
            <Route path="ProductChat" element={<ProductChat />} />
            <Route path="dashboard-product-settings" element={<SettingsServiceSection />} />
            <Route path="product-notifications" element={<NOtificationsSettingSectionMain />} />
            <Route path="product-appearance" element={<AppearanceSetting />} />
            <Route path="support" element={<HelpSupport />} />
            <Route path="promotions" element={<Promotion />} />
          </Route>

          <Route path="preview-detail-product" element={<ProductPreviewDetailPage />} />

          {/* End ---------------- Product Dashboard  (Seller-Side)




          Get Donation Dashboard (Seller-Side) */}

          <Route path="/donation-dashboard" element={<DonationDashboardLayout />}>
            {/* Child routes for dynamic content */}
            <Route path="donation-dashboard-start" element={<DonationDashboardStartHere />} />
            <Route path="setup-your-office-donation" element={<SetupYourOfficeDonations />} />
            <Route path="form-meal-neighbar" element={<MealFormSec/>} />
            <Route path="donation-dashboard-screen" element={<DonationDashSectionOne/>} />
            <Route path="campaign-Donation" element={<CampaignDonationMainSectionOne />} />
            <Route path="complete-donation-detail" element={<CompleteDonationSection />} />
            <Route path="total-donation-revenue-detail" element={<TotalRevenueDonation />} />
            <Route path="donation-bank-connect" element={<DonationBankConnect />} />
            <Route path="wallet-description-donation" element={<DonationWalletDetails />} />
            <Route path="amount-balance-wallet-donation" element={<WalletDetailAmountBalanceDonation />} />
            <Route path="dashboard-product-settings" element={<SettingsServiceSection />} />
            <Route path="product-notifications" element={<NOtificationsSettingSectionMain />} />
            <Route path="product-appearance" element={<AppearanceSetting />} />
            <Route path="support" element={<HelpSupport />} />
            <Route path="donation-events" element={<DonationEventSectionFirst />} />
            <Route path="donation-add-events" element={<DonationDashAddEventSectionFirst />} />
            <Route path="donation-volunteer-form" element={<VolunteerFormSectionFirst />} />
            <Route path="donation-volunteer-form-submit" element={<VolunteerForm />} />
            <Route path="donation-volunteer-profile-edit" element={<VolunteerProfileEditSectionFirst />} />
          </Route>


          <Route path="/non-profit" element={<Nonprofit />} />
          <Route path="/non-profit-form" element={<NonProfitForm />} />
          <Route path="/buy-monthly-subcribtion" element={<BuymonthlySubcribtion />} />
          <Route path="/buy-campaign-subcribtion" element={<BuyCampaignSubcribtion />} />
          <Route path="/email-verfication-donation" element={<EmailVerificationDonation />} />

      {/*End---------------- Get Donation Dashboard (Seller-Side) */}


          {/* Customer-Side  */}
          <Route path="/customer-dashboard" element={<CustomerDashboardDashboard />}>
          <Route path="donation-dashboard-start" element={<DonationDashboardStartHere />} />

          
          </Route>


        </Routes>
      </Router>
    </>
  );
}

export default App;
