import React, { useEffect, useState } from 'react'
import FAQ from './ServiceFaqComponent'
import vid from '../../../../media/images/Tem_Images/basil_video-outline.png'
import img from '../../../../media/images/Tem_Images/mdi_images-outline.png'
import doc from '../../../../media/images/Tem_Images/ion_documents-outline.png'
import right from '../../../../media/images/tic.png'
import { Link, useLocation } from 'react-router-dom'
import Modal from '../../../../components/Layout/Modal'

function AddQuestionsMainPage() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [isPackages, setIsPackages] = useState(false);

    const location = useLocation();
    const showModal = location.state?.showModal || false; // Check if modal should be shown
    const fixedPriceState = location.state?.fixedPriceState || false; // Check if fixedPriceState is true
    const packagesState = location.state?.packagesState || false; // Check if packagesState is true

    useEffect(() => {
        if (showModal) {
            setIsModalVisible(true); // Open the modal
            setTimeout(() => {
                setIsModalVisible(false); // Open the modal
            }, 15000); 
        }
    }, [showModal]);
 
 
    useEffect(() => {
        if (fixedPriceState) {
            setIsFixed(true);
            setIsPackages(false); 
        }

        else if (packagesState) {
            setIsPackages(true); 
            setIsFixed(false)
        }
    }, [fixedPriceState, packagesState]);

    return (
        <div className='add-faq-service-wrapper'>
            <div className="add-service-specific-item">
                <div className="add-service-describe-service-heading">
                    <h2>Frequently Asked Questions</h2>
                </div>
                <div className="add-faqs-service-questions-answers">
                    <FAQ />
                </div>
            </div>
            <div className="add-service-specific-item">
                <div className="add-service-describe-service-heading">
                    <h2>Showcase your Service</h2>
                    <p>Encourage buyers to choose your Service by featuring a variety of your work.</p>
                </div>
                {/* add other images and videos */}
                <div className="step-one-other-images">
                    <div className="input-image-video-document">
                        <div className="add-video-wrapper">
                            <div className="add-video-icon-text">
                                <img src={vid} />
                                <p>Add Video</p>
                                <span>Browze</span>
                            </div>
                        </div>
                        <div className="add-video-wrapper">
                            <div className="add-video-icon-text">
                                <img src={img} />
                                <p>Add Video</p>
                                <span>Browze</span>

                            </div>
                        </div>
                        <div className="add-video-wrapper">
                            <div className="add-video-icon-text">
                                <img src={doc} />
                                <p>Add Video</p>
                                <span>Browze</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="step-one-button">
                <div className="seller-service-dashboard-main-right-button">
                {isFixed && <Link to="/preview-details" style={{textDecoration:"none"}}  >  <button style={{ width: "100%" }} >Preview</button></Link>}
                {isPackages && <Link to="/preview-details2" style={{textDecoration:"none"}}  >  <button style={{ width: "100%" }} >Preview</button></Link>}
                {isPackages === false && isFixed === false ? <Link to="/dashboard/seller-dashboard" style={{textDecoration:"none"}}  >  <button style={{ width: "100%" }} >Go to dashboard</button></Link> : ""}
                </div>
            </div>
            <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
                <div className='modal-content-here'>
                    <div className="modal-success-image">
                        <img src={right} />
                    </div>
                    <div className="modal-content-title">
                        <h2>Service Successfully Added</h2>
                        <h3>We hope you enjoy selling on our platform</h3>
                    </div>
                    <div className="modal-content-buttons">
                        <div class="modal-content-button">
                        <Link  to="/dashboard/seller-dashboard" style={{ textDecoration: "none" }}> <button>Go to Dashboard</button></Link>
                        </div>
                    </div>

                </div>
            </Modal>
            
        </div>
    )
}

export default AddQuestionsMainPage
