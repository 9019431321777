import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ProductOptions = ({attributes, onColorChange, onSizeChange }) => {
  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);


  const handleColorChange = (color) => {
      setSelectedColor(color);
      onColorChange(color);
    } 
      
  const handleSizeChange = (size) => {
    setSelectedSize(size);
    onSizeChange(size); // Pass the selected size to parent

  }



  useEffect(() => {
    if (attributes?.length > 0) {
      const sizeAttribute = attributes.find((attr) => attr.key === 'Size');
      const colorAttribute = attributes.find((attr) => attr.key === 'Color');
  
      setSize(sizeAttribute?.value || []);
      setColor(colorAttribute?.value || []);
    }
  }, [attributes]);

  return (
    <div className="d-flex align-items-center gap-4">
      {/* Colors Section */}
      <div className="d-flex align-items-center">
        <span className="me-2">Colours:</span>
        {color?.map((color) => (
        <div
          className="rounded-circle me-2"
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: color?.color_code,
            border: selectedColor === color?.name ? `2px solid ${color?.color_code}` : "",
            cursor: "pointer",
          }}
          onClick={() => handleColorChange(color?.name)}
        ></div>
      ))}
      </div>

      {/* Sizes Section */}
     {size?.length > 0 ? <div className="d-flex align-items-center">
        <span className="me-2">Size:</span>
        {size?.map((size) => (
          <button
            key={size}
            className={`detail-size-btn ${
              selectedSize === size ? "text-black" : ""
            }`}
            onClick={() => handleSizeChange(size)}
          >
            {size?.name || "Not Available"}
          </button>
        ))}
      </div> : ""}
    </div>
  );
};

export default ProductOptions;
