import React, { useState } from 'react'

function NOtificationsSettingSectionMain() {
  const [isToggled0, setIsToggled0] = useState(false);
  const [isToggled2, setIsToggled2] = useState(false);
  const [isToggled4, setIsToggled4] = useState(false);

  const handleToggle0 = () => {
    setIsToggled0(!isToggled0);
  };
  const handleToggle2 = () => {
    setIsToggled2(!isToggled2);
  };
  const handleToggle4 = () => {
    setIsToggled4(!isToggled4);
  };

  return (
    <div className="Notifications-setting-main-wrapper">

      <div className='row'>
        <div className="col-lg-12">
          <div className="notifications-setting-heading">
            <h2>Selling Notification</h2>
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-9">
          <div className="personalized-notifications">
            <h2>Presonalized Recomendations</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
          </div>
        </div>
        <div className="col-lg-3">
              <div className="toggle-price">
                <div className={isToggled0 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle0}>
                  <div className={`toggle-price-switch ${isToggled0 ? "on" : "off"}`}></div>
                </div>
              </div>
        </div>
      </div>
      <hr></hr>
      <div className="row align-items-center">
        <div className="col-lg-9">
        <div className="personalized-notifications">
            <h2>Presonalized Recomendations</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
          </div>
        </div>
        <div className="col-lg-3">
              <div className="toggle-price">
                <div className={isToggled2 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle2}>
                  <div className={`toggle-price-switch ${isToggled2 ? "on" : "off"}`}></div>
                </div>
              </div>
        </div>
      </div>
      <hr></hr>
      <div className="row align-items-center">
        <div className="col-lg-9">
        <div className="personalized-notifications">
            <h2>Presonalized Recomendations</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
          </div>
        </div>
        <div className="col-lg-3">
              <div className="toggle-price">
                <div className={isToggled4 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle4}>
                  <div className={`toggle-price-switch ${isToggled4 ? "on" : "off"}`}></div>
                </div>
              </div>
        </div>
      </div>

    </div>
  )
}

export default NOtificationsSettingSectionMain
