import React, { useState } from 'react'
import Modal from '../../../../components/Layout/Modal';
import l1 from '../../../../media/images/Tem_Images/layout1.png'
import l2 from '../../../../media/images/Tem_Images/layout2.png'
import l3 from '../../../../media/images/Tem_Images/layout3.png'
import upload from '../../../../media/images/Tem_Images/upload.png'


function AppearanceSetting() {
    const [selectedLayout , setSelectedLayout] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [isModalVisible3, setIsModalVisible3] = useState(false);

    const selectLayot = (step) => {
        setSelectedLayout(step)
        setIsModalVisible2(true);

    }

//   for premium  modal
    const handleOrderNowClick = () => {
        setIsModalVisible(true);
    };
    
    const handleConfirm = () => {
        setIsModalVisible3(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setIsModalVisible2(false);
        setIsModalVisible3(false);
    };

  return (
    <div>
       <div className="step-form-content" >
                        <div className="row">
                           <div className="col-lg-4" onClick={() => selectLayot("1")}>
                                <div className={selectedLayout === "1" ? "layout-image-active" : "layout-image"}>
                                    <img src={l1} />
                                </div>
                                <div className="layout-heading">
                                    <h3>Template 01</h3>
                                </div>
                            </div>
                            <div className="col-lg-4" onClick={() => selectLayot("2")}>
                            <div className={selectedLayout === "2" ? "layout-image-active" : "layout-image"}>
                            <img src={l2} />
                                </div>
                                <div className="layout-heading">
                                    <h3>Template 02</h3>
                                </div>
                            </div>
                            <div className="col-lg-4" onClick={() => selectLayot("3")}>
                            <div className={selectedLayout === "3" ? "layout-image-active" : "layout-image"}>
                            <img src={l3} />
                                </div>
                                <div className="layout-heading">
                                    <h3>Template 03</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div onClick={handleOrderNowClick} className="layout-image">
                                    <div className="add-templete">
                                        <img src={upload} />
                                    </div>
                                    <div className="premium-badge">
                                        Premium
                                    </div>
                                </div>
                                <div className="layout-heading">
                                    <h3>Premium Layout</h3>
                                </div>
                            </div>
                            <div className="col-lg-4">

                            </div>
                            <div className="col-lg-4">

                            </div>
                        </div>
                    </div>

        {/* upgrade to premium ? */}
            <Modal isVisible={isModalVisible} onClose={handleCloseModal}>
                <div className='modal-content-here'>
                    <div className="modal-content-title">
                        <h2>Upgrade to premium</h2>
                    </div>
                    <div className="modal-content-buttons">
                        <div class="modal-content-button" >
                            <button>Use Premium layout</button>
                        </div>
                        <div class="modal-content-button">
                            <button>Design a Custom Layout</button>
                        </div>
                    </div>
                </div>
            </Modal>

        {/* upgrade to premium ? */}
            <Modal isVisible={isModalVisible2} onClose={handleCloseModal}>
                <div className='modal-content-here'>
                    <div className="modal-content-title-con">
                        <h3>Are you sure ?</h3>
                    </div>
                    <div className="modal-content-buttons-conformation">
                        <div class="modal-content-button-con" >
                            <button onClick={handleConfirm}>Yes</button>
                        </div>
                        <div class="modal-content-button-con-no">
                            <button onClick={handleCloseModal} >No</button>
                        </div>
                    </div>
                </div>
            </Modal>

        {/* upgrade to premium ? */}
            <Modal isVisible={isModalVisible3} onClose={handleCloseModal}>
                <div className='modal-content-here'>
                    <div className="modal-content-title-confirmed">
                        <h3>Successfully Changed!</h3>
                        <p>You have successfully changed the layout.</p>
                    </div>
                    <div className="modal-content-buttons-conformation">
                        <div class="modal-content-button-con-no">
                            <button onClick={handleCloseModal} >Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
    </div>
  )
}

export default AppearanceSetting
