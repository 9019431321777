import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // For schema validation
import signuplogo from '../media/images/logo-sign-up.png';
import eye from '../media/images/eye.svg';
import eyeHide from '../media/images/eyeHide.svg';
import { registerUser } from '../utils/api'; // Import the API function
import Swal from 'sweetalert2';

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const navigate = useNavigate();

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    address: Yup.string().required('Address is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  // Initial form values
  const initialValues = {
    name: '',
    email: '',
    address: '',
    password: '',
    password_confirmation: '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await registerUser({
        ...values,
        is_user: 1,
        package_id: 1,
      });

      // Show success alert
      await Swal.fire({
        icon: 'success',
        text: 'Your account has been created successfully.',
        confirmButtonText: 'OK',
      });

      // Navigate on success
      navigate('/reset-password-otp', { state: { email: values.email } });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        text: err.message || 'Something went wrong!',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="sign-up">
      <div className="sign-form">
        <div className="form-wrapper">
          <div className="sign-up-image">
            <Link to="/"><img src={signuplogo} alt="Sign Up Logo" /></Link>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="form-main-signup">
                <div>
                  <p>Name</p>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Example"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>

                <div>
                  <p>Email</p>
                  <Field
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                  />
                  <ErrorMessage name="email" component="div" className="error" />
                </div>

                <div>
                  <p>Address</p>
                  <Field
                    type="text"
                    name="address"
                    placeholder="New York City, USA"
                  />
                  <ErrorMessage name="address" component="div" className="error" />
                </div>

                <div style={{ position: 'relative' }}>
                  <p>Password</p>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="*************"
                  />
                  <img
                    src={showPassword ? eye : eyeHide}
                    onClick={() => setShowPassword((prev) => !prev)}
                    alt="Toggle Password"
                    style={{
                      width: "35px",
                      position: 'absolute',
                      right: '1.5rem',
                      top: '4.8rem',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                      color: '#888',
                    }}
                  />
                  <ErrorMessage name="password" component="div" className="error" />
                </div>

                <div style={{ position: 'relative' }}>
                  <p>Confirm Password</p>
                  <Field
                    type={showCPassword ? "text" : "password"}
                    name="password_confirmation"
                    placeholder="*************"
                  />
                  <img
                    src={showCPassword ? eye : eyeHide}
                    onClick={() => setShowCPassword((prev) => !prev)}
                    alt="Toggle Password"
                    style={{
                      width: "35px",
                      position: 'absolute',
                      right: '1.5rem',
                      top: '4.8rem',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                      color: '#888',
                    }}
                  />
                  <ErrorMessage name="password_confirmation" component="div" className="error" />
                </div>

                <div className="signup-bottom-text">
                  <p>Already Registered? <Link to="/login" className="link">Login</Link></p>
                </div>

                <div className="sign-up-form-button">
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
