import React, { useState } from 'react'
import upload from '../../../../media/images/Tem_Images/upload.png'
import arr from '../../../../media/images/Vector.png'
import arrd from '../../../../media/images/arrowDown.png'
import Toogle from '../../../../components/Main/Toogle';
import { Link, useNavigate } from 'react-router-dom';

function AddServiceMainPage() {
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [packageSelection, setPackageSelection] = useState("");

    const [fixedPrice, setFixedPrice] = useState(true);
    const [hourly, setHourly] = useState(false);

    const [isToggled, setIsToggled] = useState(true);
    const [isToggled2, setIsToggled2] = useState(false);
    const [isToggled3, setIsToggled3] = useState(false);
    const [isToggled4, setIsToggled4] = useState(false);

    const [dropdownStatus, setDropdownStatus] = useState(false);
    const [dropdownStatus2, setDropdownStatus2] = useState(false);
    const [priceToggle, setPriceToggle] = useState(false);
    const [daysToggle, setDaysToggle] = useState(false);

    const categories = ["Category 1", "Category 2", "Category 3"];
    const subCategories = ["Sub-Category 1", "Sub-Category 2", "Sub-Category 3"];
    const navigate = useNavigate()

    const handleDropdown1 = () => {
        setDropdownStatus(!dropdownStatus)
        setDropdownStatus2(false)
        setPriceToggle(false)
    }
    const handleDropdown2 = () => {
        setDropdownStatus2(!dropdownStatus2)
        setDropdownStatus(false)
        setPriceToggle(false)


    }
    const handlePriceDropdown = () => {
        setPriceToggle(!priceToggle)
        setDropdownStatus(false)
        setDropdownStatus2(false)
    }
    const handleDaysDropdown = () => {
        setDaysToggle(!daysToggle)
        setPriceToggle(false)
        setDropdownStatus(false)
        setDropdownStatus2(false)
    }

    const handleToggle = () => {
        setIsToggled(!isToggled);
        setIsToggled2(false);
        setFixedPrice(true)
        setHourly(false)


    };
    const handleToggle3 = () => {
        setIsToggled3(!isToggled3);
    };
    const handleToggle4 = () => {
        setIsToggled4(!isToggled4);
    };
    const handleToggle2 = () => {
        setIsToggled2(!isToggled2);
        setIsToggled(false);
        setFixedPrice(false)
        setHourly(true)

    };
    const handlePackage = (i) => {
        setPackageSelection(i)
    };


//  navigationsss to preview

    const handleFixedPriceNavigate = () => {
        navigate("/dashboard/add-service-details", { state: { fixedPriceState: true } })

    }

    const handlePackagesNavigate = () => {
        navigate("/dashboard/add-service-details", { state: { packagesState: true } })

    }







    return (
        <div className='add-service-wrapper'>
            {/* add cover image */}
            <div className="add-service-cover-image">
                <div className="add-service-cover-image-heading">
                    <h2>Add Cover Image</h2>
                </div>
                <div className="add-service-cover-image-input">
                    <img src={upload} />
                    <p>Upload Image</p>
                </div>
            </div>

            {/* describe your service */}
            <div className="add-service-describe-your-service">
                <div className="add-service-describe-service-heading">
                    <h2>Describe Your Service</h2>
                </div>
                <div className="add-service-describe-service-input">
                    <textarea placeholder='Write something......' />
                </div>

            </div>

            {/* item specific */}
            <div className="add-service-specific-item">
                <div className="add-service-describe-service-heading">
                    <h2>Item Specifics</h2>
                </div>
            </div>

            {/* service title */}
            <div className="add-service-title-input">
                <input placeholder='Service Title' />
            </div>
            {/* catagory and sub catagory*/}
            <div className="add-service-catagory-sub-catagory">
                <div className="add-service-cat-dropdown" onClick={handleDropdown1}>
                    <button>  {selectedCategory || "Select A Category"} <span><img src={arr} /></span></button>
                    {dropdownStatus && <ul>
                        {categories.map((category, index) => (
                            <li
                                key={index}
                                onClick={() => setSelectedCategory(category)}
                            >
                                {category}
                            </li>
                        ))}
                    </ul>}
                </div>
                <div className="add-service-sub-cat-dropdown" onClick={handleDropdown2}>
                    <button> {selectedSubCategory || "Select A Category"} <span><img src={arr} /></span></button>
                    {dropdownStatus2 && <ul>
                        {subCategories.map((category, index) => (
                            <li
                                key={index}
                                onClick={() => setSelectedSubCategory(category)}
                            >
                                {category}
                            </li>
                        ))}
                    </ul>}
                </div>

            </div>

            {/* service keyword */}
            <div className="add-service-title-input">
                <input placeholder='Keywords' />
            </div>

            {/* Delivery Time */}
            <div className="add-service-title-input">
                <input placeholder='Delivery Time' />
            </div>

            {/* Price Dropdown */}
            <div className="add-service-Price-Dropdown">
                <div className="price-toggle-button">
                    <button onClick={handlePriceDropdown} >Price</button>
                    <img src={arrd} />
                </div>
                {priceToggle && <ul>
                    <div className="fixed-price-toggle">
                        <li>Fixed</li>
                        <div className="toggle-price">
                            <div className={isToggled ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle}>
                                <div className={`toggle-price-switch ${isToggled ? "on" : "off"}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className="hourly-toggle">
                        <li>Hourly</li>
                        <div className="toggle-price">
                            <div className={isToggled2 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle2}>
                                <div className={`toggle-price-switch ${isToggled2 ? "on" : "off"}`}></div>
                            </div>
                        </div>
                    </div>
                </ul>}
            </div>

            {fixedPrice && isToggled && <div className="fixed-price-div">
                {/* Set Price */}
                <div className="add-service-set-price-input">
                    <p>Set Price</p>
                    <input placeholder='$45.00' />
                </div>


                {/* Set Packages */}
                <div className="add-service-set-packages">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="add-service-set-pack-6-input">
                                <p>Packages</p>
                                <div className="toggle-price">
                                    <div className={isToggled3 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle3}>
                                        <div className={`toggle-price-switch ${isToggled3 ? "on" : "off"}`}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className={packageSelection === "B" ? "add-service-set-pack-input-open" : "add-service-set-pack-input"} onClick={() => handlePackage("B")}>
                                <p>Basic</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className={packageSelection === "S" ? "add-service-set-pack-input-open" : "add-service-set-pack-input"} onClick={() => handlePackage("S")}>
                                <p>Standard</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className={packageSelection === "A" ? "add-service-set-pack-input-open" : "add-service-set-pack-input"} onClick={() => handlePackage("A")}>
                                <p>Advance</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* details table */}
                <div className="detail-table-for-fixed-price">
                    <div className="row">
                        <div className="col-lg-6">
                            <p>Set Price</p>
                        </div>
                        <div className="col-lg-2">
                            <p>$45.00</p>
                        </div>
                        <div className="col-lg-2">
                            <p>$45.00</p>
                        </div>
                        <div className="col-lg-2">
                            <p>$45.00</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <p>Details</p>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <p>Delivery Time</p>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>

                <div className="step-one-button">
                    <div className="seller-service-dashboard-main-right-button">
                    <button onClick={handleFixedPriceNavigate} style={{ width: "100%" }} >Save & Continue</button>
                    </div>
                </div>
            </div>}



            {hourly && isToggled2 && <div className="hourly-div">
                {/* Set hourly Price */}
                <div className="add-service-set-price-input">
                    <p>Set Hourly Price</p>
                    <input placeholder='$15.00' />
                </div>

                {/* Book Appointment */}
                <div className="add-service-set-packages">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="add-service-set-pack-6-input">
                                <p>Book Appointment</p>
                                <div className="toggle-price">
                                    <div className={isToggled4 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle4}>
                                        <div className={`toggle-price-switch ${isToggled4 ? "on" : "off"}`}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Select Available Time */}
                <div className="add-service-set-price-input">
                    <p>Select Available Time</p>
                    <input type='time' placeholder='Select Available Time' />

                </div>


                {/* Price Dropdown */}
                <div className="add-service-Price-Dropdown">
                    <div className="price-toggle-button">
                        <button onClick={handleDaysDropdown} >Select Days Available</button>
                        <img src={arrd} />
                    </div>
                    {daysToggle &&
                        <div className="days-dropdown">
                            <p>Monday</p>
                            <p>Tuesday</p>
                            <p>Wednesday</p>
                            <p>Thursday</p>
                            <p>Friday </p>
                            <p>Saturday </p>
                            <p style={{ color: "lightgray" }}>Sunday </p>
                        </div>}
                </div>

                <div className="step-one-button">
                    <div className="seller-service-dashboard-main-right-button">
                     <button onClick={handlePackagesNavigate} style={{ width: "100%" }} >Save & Continue</button>
                    </div>
                </div>
            </div>}

        </div>
    )
}

export default AddServiceMainPage
