import React, { useState } from 'react'
import vector from '../../../../media/images/Vector.png'
import upload from '../../../../media/images/Tem_Images/upload.png'
import cross from '../../../../media/images/Group 1000006644.png'

function Promotion() {
    const [active, setActive] = useState(false)
    const [active2, setActive2] = useState(false)
    const [tabActive, setTabActive] = useState("tab1")
    const [selectedImage, setSelectedImage] = useState(null);

    const handlePromotionsDropDown = () => {
        setActive(!active)
    }
    const handlePromotionsDropDown2 = () => {
        setActive2(!active2)
    }
    const handleTabs = (e) => {
        setTabActive(e)
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setSelectedImage(imageURL); // Set the uploaded image
        }
    };

    const handleCrossImage = () => {
        setSelectedImage(null)
    }
    return (
        <div className='promotionsandoffer-main'>
            <div className="promotions-tabs-top">
                <tab onClick={() => handleTabs("tab1")} className={tabActive === "tab1" ? "active-tab" : "not-active-tab"}>Promotions</tab>
                <tab onClick={() => handleTabs("tab2")} className={tabActive === "tab2" ? "active-tab" : "not-active-tab"}>Offers</tab>
            </div>
            {tabActive === "tab1" ? (<div className="promotions-form-wrapper">
                <form>

                    <div className="add-promotion-input-wrapper">
                        <input type='text' placeholder="Coupon Name" />
                    </div>
                    <div className="add-promotion-input-wrapper">
                        <input type='text' placeholder="Coupon Code" />
                    </div>
                    <div className='add-promotion-input-dates-wrapper'>
                        <span>End date</span>
                        <span>start date</span>
                    </div>
                    <div className="add-promotion-set-price-input">
                        <p>Min Order</p>
                        <input placeholder="$45.00" />
                        <div className="input-dropdown-icon">
                            <img src={vector} onClick={handlePromotionsDropDown} alt="dropdown-icon" />
                        </div>
                    </div>
                    <div className="promotions-drop-down-wrapper">
                        <div
                            className={`min-order-drop-down ${active ? "slide-down" : "slide-up"}`}
                        >
                            <ul>
                                <li>test 1</li>
                                <li>test 2</li>
                                <li>test 3</li>
                            </ul>
                        </div>
                    </div>
                    <div className="add-promotion-set-price-input">
                        <p>Max Discount</p>
                        <input placeholder='$45.00' />
                    </div>
                    <div className="add-promotion-set-price-input">
                        <p>Coupon Quantity Limit</p>
                        <input placeholder='$45.00' />
                        <div className="input-dropdown-icon">
                            <img src={vector} onClick={handlePromotionsDropDown2} />
                        </div>
                    </div>
                    <div className="promotions-drop-down-wrapper">
                        <div
                            className={`min-order-drop-down ${active2 ? "slide-down" : "slide-up"}`}
                        >
                            <ul>
                                <li>test 1</li>
                                <li>test 2</li>
                                <li>test 3</li>
                            </ul>
                        </div>
                    </div>
                    <button type='button'>Upload</button>
                </form>
            </div>) : (
                 <div className="promotions-tab-2-wrapper">
                    <div className="upload-cover-picture-wrapper" style={{ position: "relative" }}>
                        <div className="upload-cover-pic-icon">
                         <img 
                             src={upload} 
                             alt="Upload Icon" 
                             className="upload-top-banner-promotions" 
                         />
                         <input 
                             type="file" 
                             accept="image/*" // Accept only images
                             onChange={handleImageUpload} 
                             style={{ display: "block" ,
                                position: "absolute",
                                top: "55%",
                                right: "33%",
                                rotate: "90deg",
                                height: "66px",
                                opacity: 0,
                        }} 
                             id="upload-banner-input" 
                         />
                         <p htmlFor="upload-banner-input" style={{ cursor: "pointer" }}>
                             <p>Upload Top Banner</p>
                         </p>
                     </div>
                 </div>
                 {selectedImage && (
                     <div className="top-banner-uploaded-image">
                         <img src={selectedImage} alt="Uploaded Banner" />
                         <div className='cross-image-top-banner'>
                                <img src={cross} onClick={handleCrossImage}/>
                        </div>
                     </div>
                 )}
                
                <div className="copy-promotion-link">
                    <input type="text" placeholder='https://www.figma.com/design/Opening-Heart-Final-Files-Website' />
                    <button>Copy</button>
                </div>
                
                <div className="end-start-date-calender-promotions">
                    calender here
                </div>
                <div className="promotion-purchase-button">
                    <button type='button'>Purchase</button>
                </div>

             </div>
            )}
        </div>
    )
}

export default Promotion
