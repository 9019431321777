import React, { useEffect, useState } from 'react'
import ShoppingCartCard from './ShoppingCartCard'
import Header from '../../components/Layout/Header'
import ShoppingCartSectionTwo from './ShoppingCartSectionTwo'
import SliderOneDetailPage from '../Feature Product/ProductDetail/slider_other-recomendations/SliderOneDetailPage'
import Footer from '../../components/Layout/Footer'
import { checkOutCart } from '../../utils/api'

function ShoppingCart() {

  return (
    <div>
      <Header />
      <ShoppingCartSectionTwo/>
      
      {/* Conditional rendering if no cart items */}
      {/* <div className="container">
        {catItem?.length === 0 ? (
          "No data available"
        ) : (
          <div className="sliderOne">
            <h2>Others recommendations for you</h2>
            <SliderOneDetailPage />
          </div>

        )}
      </div>       */}
      <Footer />
    </div>
  )
}

export default ShoppingCart;
