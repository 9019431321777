import React from 'react'
import AboutMethew from '../../../../screens/Service Flow/Service Sell Detail page/AboutMethew'
import GetToKnowAbtMethew from '../../../../screens/Service Flow/Service Sell Detail page/GetToKnowAbtMethew'
import OtherRecomendationsForSeller from '../../../../screens/Service Flow/Service Sell Detail page/OtherRecomendationsForSeller'
import Footer from '../../../../components/Layout/Footer'
import FixedPriceSection from './FixedPriceSection'
import HeaderTop from '../../../../components/Layout/HeaderTop'
import PricePreviewTop from './PricePreviewTop'
import SingleServiceFaqs from '../../../../screens/Service Flow/Single Service Detail page/SingleServiceFaqs'

function PreviewFixedPrice() {
  return (
    <div>
        <HeaderTop/>
        <PricePreviewTop/>
        <FixedPriceSection/>
        <AboutMethew/>
        <GetToKnowAbtMethew/>
        <SingleServiceFaqs />
        <OtherRecomendationsForSeller/>
        <Footer/>
    </div>
  )
}

export default PreviewFixedPrice
