import axios from 'axios';

// Base URL for the API
const BASE_URL = 'https://openheartsbackend.testingwebsitelink.com/api/';
export default BASE_URL;

export const STRIPE_PUBLISH_KEY = "pk_test_51HiCo6EHLDkHxi1YwwTc185yQTBuRIZktAiqLEus7vFq1kKxsrir4UlAUVCP6rRokopLAFCYY1DKowhrjZuLhyv200gfW8PqZc";

// Function to register user
export const registerUser = async (userData) => await axios.post(`${BASE_URL}auth/register`, userData);

export const verifyOtp = async (data) => await axios.post(`${BASE_URL}auth/verify-otp`, data);

export const LoginUser = async (data) => await axios.post(`${BASE_URL}auth/login`, data);
export const ForgetPassword = async (data) => await axios.post(`${BASE_URL}auth/send-otp`, data);

export const ChangePassword = async (data) => await axios.post(`${BASE_URL}auth/reset-password`, data);

export const allFeaturedProducts = async (brand, min, max, cat, page, selectedCountry, tabFilter) => await axios.get(`${BASE_URL}products/filter-product?brand=${brand}&min_price=${min}&max_price=${max}&category=${cat}&page=${page}&city=${selectedCountry}&type=${tabFilter}`);

export const allFeaturedProductsBanner = async () => await axios.get(`${BASE_URL}banner/product`);
export const allFeaturedServiceBanner = async () => await axios.get(`${BASE_URL}banner/service`);

// top rated products 
export const homeBanner = async () => await axios.get(`${BASE_URL}banner`);

// top rated categories 
export const topRatedCategories = async () => await axios.get(`${BASE_URL}categories/home-category`);

// top rated products 
export const topRatedProducts = async (page, page_size) => await axios.get(`${BASE_URL}products/top-selling?page=${page}&page_size=${page_size}`);
// top rated stores 
export const topRatedStores = async () => await axios.get(`${BASE_URL}home/top-rated-store`);

export const topRatedService = async () => await axios.get(`${BASE_URL}service/popular-services`);

// top rated stores 
export const topRatedCampaings = async () => await axios.get(`${BASE_URL}organization-user/campaigns`);

// product all categories
export const productAllCategories = async () => await axios.get(`${BASE_URL}categories`);

export const popularService = async () => await axios.get(`${BASE_URL}service/popular-services`);

export const topRatedServiceProvider = async () => await axios.get(`${BASE_URL}service/top-rated-service-provider`);

export const serviceAllCategories = async () => await axios.get(`${BASE_URL}categories/service`);

export const organizationAllCategories = async () => await axios.get(`${BASE_URL}organization-category`);

export const fetchProductPageSuplier = async () => await axios.get(`${BASE_URL}products/filter-supplires?category=${50}`);

export const fetchVendorStore = async (id) => await axios.get(`${BASE_URL}user-seller/about/${id}`);

export const fetchVendorRatings = async (id) => await axios.get(`${BASE_URL}user-seller/feedback/${id}`);

export const fetchVendorStoreProducts = async (id) => await axios.get(`${BASE_URL}user-seller/products/${id}?page=${1}`);

export const productDetailApi = async (id) => await axios.get(`${BASE_URL}products/getProductById/${id}`);

export const productRatingAndReviews = async (id) => await axios.get(`${BASE_URL}products/product-feedback?product_id=${id}`);

export const relatedproducts = async (id) => await axios.get(`${BASE_URL}products/related/${id}`);

export const removeCart = async (id) => await axios.post(`${BASE_URL}cart/delete?cart_id=${id}`);

export const serviceDetail = async (id) => await axios.get(`${BASE_URL}service/service-by-id/${id}`);

export const checkOutCart = async () => {
  const token = localStorage.getItem('login-token'); // Get the token from localStorage
  const headers = token ? { Authorization: `Bearer ${token}` } : {}; // Set the Authorization header as an object

  try {
    const response = await axios.get(`${BASE_URL}cart/checkout`, { headers });
    return response; // Return the response from the API
  } catch (error) {
    console.error('Error during checkout:', error); // Handle the error
    throw error;
  }
};
export const checkOutServiceCart = async () => {
  const token = localStorage.getItem('login-token'); // Get the token from localStorage
  const headers = token ? { Authorization: `Bearer ${token}` } : {}; // Set the Authorization header as an object

  try {
    const response = await axios.get(`${BASE_URL}service-cart/get-cart`, { headers });
    return response; // Return the response from the API
  } catch (error) {
    console.error('Error during checkout:', error); // Handle the error
    throw error;
  }
};
export const checkOutBothPrice = async () => {
  const token = localStorage.getItem('login-token'); // Get the token from localStorage
  const headers = token ? { Authorization: `Bearer ${token}` } : {}; // Set the Authorization header as an object

  try {
    const response = await axios.get(`${BASE_URL}cart/seller-product-cart`, { headers });
    return response; // Return the response from the API
  } catch (error) {
    console.error('Error during checkout:', error); // Handle the error
    throw error;
  }
};

export const order = async (data) => {
  const token = localStorage.getItem('login-token'); // Get the token from localStorage
  const headers = token ? { Authorization: `Bearer ${token}` } : {}; // Set the Authorization header as an object

  try {
    // Change axios.get to axios.post and include data in the body
    const response = await axios.post(`${BASE_URL}order`, data, { headers });
    return response; // Return the response from the API
  } catch (error) {
    console.error('Error during checkout:', error); // Handle the error
    throw error;
  }
};

export const removeServiceCart = async (data) => {
  const token = localStorage.getItem('login-token'); // Get the token from localStorage
  const headers = token ? { Authorization: `Bearer ${token}` } : {}; // Set the Authorization header as an object

  try {
    // Change axios.get to axios.post and include data in the body
    const response = await axios.post(`${BASE_URL}service-cart/remove-cart-by-id/37`, data , { headers });
    return response; // Return the response from the API
  } catch (error) {
    console.error('Error during checkout:', error); // Handle the error
    throw error;
  }
};


export const addToCartApi = async (formData) => {
  const token = localStorage.getItem('login-token'); // Get the token from localStorage
  const headers = token ? { Authorization: `Bearer ${token}` } : {}; // Set the Authorization header as an object


  await axios.post(`${BASE_URL}cart/store-update`, formData, { headers });

}




