import React, { useState } from 'react'
import user from "../../../../media/images/settingsicon.png"
import camera from "../../../../media/images/camera icon.png"

function SettingsServiceSection() {
    const [userImage, setUserImage] = useState(user);

    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setUserImage(reader.result); // Set the uploaded image as the user's profile picture
        };
        reader.readAsDataURL(file);
      }
    };
  
    const triggerFileInput = () => {
      document.getElementById("imageUpload").click();
    };
  return (
    <>
    <div className='row d-flex align-items-center'>
    <div className="col-lg-3">
        <div className="service-settings-user-profile">
          <div className="service-settings-user-profile-image">
            <img src={userImage} alt="User Profile" />
            <div 
              className="service-settings-user-profile-camera-image" 
              onClick={triggerFileInput} // Trigger file input on click
              style={{ cursor: 'pointer' }}
            >
              <img src={camera} alt="Camera Icon" />
            </div>
            {/* Hidden file input */}
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-9">
            <div className="service-settings-user-profile-content">
                <h1>Adam Roberson (4737594)</h1>
                <h3>Joined since : 2019</h3>
            </div>
      </div>
    </div>

    <div className="row mt-4">
        <div className="col-lg-12">
            <div className="setting-service-dashboard-main-heading">
                <h2>Edit Store Info</h2>
            </div>
        </div>
    </div>

            <div className="setting-service-dashboard-inputs-main">
         <div className="row mt-4 mb-4">
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>Full Name</span>
                            <input type='text'  defaultValue={"Adam Roberson"}/>
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>Phone</span>
                            <input type='text'  defaultValue={"Adam Roberson"}/>
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                </div>
         </div>
         <div className="row mt-4 mb-4">
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>Email</span>
                            <input type='email'  defaultValue={"Adam@example.com"} />
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>Address</span>
                            <input type='text' defaultValue={"----------------------"} />
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                </div>
         </div>
         <div className="row mt-4 mb-4">
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>Country</span>
                            <input type='text'  defaultValue={"Australia"}/>
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>City</span>
                            <input type='text' defaultValue={"Sydney"} />
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                </div>
         </div>
         <div className="row mt-4 mb-4">
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>Password</span>
                            <input type='password' defaultValue={"****************"} />
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                    <div className='change-password'>
                        <p>change password</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="input-form-wrapper-service-settings">
                        <div className="setting-service-dashboard-input-wrapper">
                            <span>Zip Code</span>
                            <input type='text'  />
                        </div>  
                            <div className='pencil'>✏️</div>
                    </div>
                </div>
         </div>
         <div className="row mt-4 mb-4">
            <div className="col-lg-12">
            <div className="service-setting-save-button">
                <button>Save</button>
            </div>
            </div>
         </div>
            </div>
    </>
  
  )
}

export default SettingsServiceSection
