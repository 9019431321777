import React, { useState, useRef } from "react";
import emojiIcon from "../../../../media/images/smile.png";
import galleryIcon from "../../../../media/images/imageInput.png";
import micIcon from "../../../../media/images/mic.png";
import InputEmoji from "react-input-emoji";

const ChatView = ({ selectedChat }) => {
    const [messages, setMessages] = useState([
        { text: "How does it work?", sender: "other" },
        { text: "Simple", sender: "me" },
        { text: "You just edit any text...", sender: "me" },
    ]);
    const [newMessage, setNewMessage] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [audioURL, setAudioURL] = useState("");

    const handleOnEnter = (e) => {
        if (e?.trim()) {
            setMessages([...messages, { text: e, sender: "me" }]);
            setNewMessage("");
        }
    };

    // Audio Recording Handlers
    const startRecording = async () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;

                mediaRecorder.start();
                setIsRecording(true);

                const audioChunks = [];
                mediaRecorder.ondataavailable = (event) => {
                    audioChunks.push(event.data);
                };

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
                    const audioURL = URL.createObjectURL(audioBlob);
                    setAudioURL(audioURL);
                    setMessages([...messages, { text: audioURL, sender: "me", type: "audio" }]);
                };
            } catch (error) {
                console.error("Error accessing audio device:", error);
            }
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    // Image Upload Handler
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        const imageMessages = files.map((file) => ({
            text: URL.createObjectURL(file),
            sender: "me",
            type: "image",
        }));
        setMessages([...messages, ...imageMessages]);
    };

    if (!selectedChat) {
        return (
            <div className="chat-view-start">
                <h3>Start new conversation</h3>
            </div>
        );
    }

    return (
        <div style={{ flex: 1, display: "flex", flexDirection: "column", background: "#fff" }}>
            <div className="selected-chat-top-wrapper" style={{ padding: "20px", borderBottom: "1px solid #ddd", fontWeight: "bold" }}>
                <div className="d-flex align-items-center gap-10">
                    <div className="selected-chat-image">
                        <img src={selectedChat.profilePic} />
                    </div>
                    <div>
                        <span className="chat-view-user-name">{selectedChat.name} </span>
                        <div className="chat-view-user-active-time">Active 20m ago </div>
                    </div>
                </div>
            </div>
            <div style={{ flex: 1, padding: "20px", overflowY: "auto" }}>
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={msg.sender === "me" ? "chat-view-message-me-main" : "chat-view-message-user-main"}
                    >
                        {msg.type === "audio" ? (
                            <audio controls src={msg.text}></audio>
                        ) : msg.type === "image" ? (
                            <img src={msg.text} alt="Uploaded" style={{ maxWidth: "200px" }} />
                        ) : (
                            <button>{msg.text}</button>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ padding: "10px", borderTop: "1px solid #ddd", position: "relative" }}>
                <InputEmoji
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target?.value)}
                    onEnter={handleOnEnter}
                    placeholder="Type a message"
                    style={{
                        flex: 1,
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        outline: "none",
                    }}
                />
                <div className="input-chat-icons" style={{ display: "flex", gap: "15px" }}>
                    <button
                        onMouseDown={startRecording}
                        onMouseUp={stopRecording}
                    >
                        <img src={micIcon} alt="Mic" style={{marginBottom: "3px"}} />
                    </button>
                    <label>
                        <img src={galleryIcon} alt="Gallery" />
                        <input
                            type="file"
                            multiple
                            accept="image/*"
                            style={{ display: "none" , zIndex:"4" }}
                            onChange={handleImageUpload}
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ChatView;
