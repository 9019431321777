import React, { useState } from 'react'
import ProductChatList from './ProductChatList'
import ProductChatView from './ProductChatView'

function ProductChat() {
    const [selectedChat, setSelectedChat] = useState(1);


  return (
    <div style={{ display: "flex", height: "75vh", background: "#fff" }}>
    <ProductChatList setSelectedChat={setSelectedChat} />
    <ProductChatView selectedChat={selectedChat} />
  </div>
  )
}

export default ProductChat
