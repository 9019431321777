import React, { useState } from "react"

function FAQ() {
  const [faqs, setFaqs] = useState([{ question: "", answer: "" }]);

  const handleAddMore = () => {
    setFaqs([...faqs, { question: "", answer: "" }]);
  };

  const handleDelete = (index) => {
    const updatedFaqs = faqs.filter((_, i) => i !== index);
    setFaqs(updatedFaqs);
  };

  const handleInputChange = (index, field, value) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index][field] = value;
    setFaqs(updatedFaqs);
  };

  return (
    <div className="add-service-faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="add-service-faq-item">
          <div className="add-service-faq-inputs">
            <input
              type="text"
              className="add-service-faq-question"
              placeholder="Add Question"
              value={faq.question}
              onChange={(e) =>
                handleInputChange(index, "question", e.target.value)
              }
            />
            <textarea
              className="add-service-faq-answer"
              placeholder="Answer"
              value={faq.answer}
              onChange={(e) =>
                handleInputChange(index, "answer", e.target.value)
              }
            />
          </div>
          <button
            className="add-service-faq-delete"
            onClick={() => handleDelete(index)}
            disabled = {faqs.length < 2 ? true : false}
          > Remove </button>
        </div>
      ))}
      <button className="add-service-faq-add-more" onClick={handleAddMore}>
        + Add More
      </button>
    </div>
  );
}

export default FAQ;
