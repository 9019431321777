import React from "react";
import akane from '../../../../media/images/akane.png'
import search from '../../../../media/images/search.svg'

const chats = [
  { id: 1, name: "My AI Chat", lastMessage: "Will head to the Help Center...", profilePic: akane },
  { id: 2, name: "Simion D", lastMessage: "Let's go", profilePic: akane },
  { id: 3, name: "Daren Smith", lastMessage: "Let's go", profilePic: akane },
  { id: 4, name: "Elvis Fer", lastMessage: "Let's go", profilePic: akane },
  { id: 5, name: "Denny", lastMessage: "Let's go", profilePic: akane },
  { id: 6, name: "Garry", lastMessage: "Let's go", profilePic: akane }
  // Add more dummy chats as needed
];  

const ChatList = ({ setSelectedChat }) => {
  return (
    <div style={{ width: "35%", borderRight: "1px solid #ddd", overflowY: "auto" }}>
        <div className="chat-search-search-box">
            <input type="text" placeholder="Search for any service..." className="chat-search-input" />
            <span className="chat-search-icon"><img src={search} /></span>
        </div>

      {chats.map((chat) => (
        <div
          key={chat.id}
          onClick={() => setSelectedChat(chat)}
          className="chat-list-chat-main"
        >
          <img src={chat.profilePic} alt={chat.name} style={{ borderRadius: "50%", marginRight: "10px" }} />
          <div>
            <div className="chat-list-chat-name">{chat.name}</div>
            <div  className="chat-list-chat-last-message" style={{ color: "gray", fontSize: "0.9em" }}>{chat.lastMessage}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatList;
