import React, { useState } from "react";
import sampleImage from "../../../media/images/Rectangle 18258.png"; // Replace with your image path
import user from "../../../media/images/methewSmall.png"; // Replace with your image path
import userChatIcon from "../../../media/images/chat.png"; // Replace with your chat icon
import afg from "../../../media/images/Afghanistan (AF).png"; // Replace with your chat icon
import s from '../../../media/images/singleStar.png'
import ReviewSliderMain from "./ReviewSliderMain";
import { Link } from "react-router-dom";
import Calender from "../Service_Calender/Calender";

const ServiceSellerGallery = ({data, data_review}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [imageShow, setImageShow] = useState(sampleImage);

    // const handleOrderNowClick = () => {
    //   setIsModalVisible(true);
    // };
  
    const handleCloseModal = () => {
      setIsModalVisible(false);
    };

    const handleImageSet = (img) => {
        setImageShow(img)
    }


    const imageGallery = [
        sampleImage, sampleImage, sampleImage, sampleImage, sampleImage, sampleImage
    ]; // Replace with actual image paths
    const testimonials = [
        {
          name: "Mathew",
          countryFlag: afg, // Replace with the actual image or emoji
          image: user, // Placeholder for profile image
          stars: 5,
          text: "Boost brand exposure during our biggest sourcing events and online trade shows, including Super September and March Expo.",
        },
    ]
    return (

        <div className="container">
            <div className="appointment-card">

                <div className="row">

                    {/* Left Section - Image and Gallery */}
                    <div className="col-lg-6">
                        <div className="image-section">
                            <img src={imageShow} alt="Main Display" className="main-image" />
                            <div className="gallery">
                                {data?.images?.map((img, index) => (
                                    <img
                                        key={index}
                                        src={img}
                                        onClick={() => handleImageSet(img)}
                                        alt={`Thumbnail ${index + 1}`}
                                        className="gallery-image"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Right Section - Content */}
                    <div className="col-lg-6">
                        <div className="content-section">
                            <h2 className="appointment-title">Book an appointment</h2>
                            <p className="appointment-text">Choose a preferred date and time</p>
                            <button className="appointment-button" >Hire Now</button>
                            <div className="working-hours">
                                <h3 className="working-title">Working Hours</h3>
                                <p className="working-time">UTC {data?.select_available_time}</p>
                                <p className="working-days">{data?.select_avaiable_day}</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="chat-section">
                                        <img src={userChatIcon} alt="Chat Icon" className="chat-icon" />
                                        <p className="chat-text">Chat with Mathew</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="hourly-rate">
                                        <p>${data?.set_price}/{data?.delivery_time}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Calender isVisible={isModalVisible} onClose={handleCloseModal}/>
                    </div>
                </div>
            </div>
            <div className="reviwe-slider">
                <div className="row">
                    <div className="col-lg-12">
                <ReviewSliderMain data_review={data_review}/>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ServiceSellerGallery;
