import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/ContextApi/AppContext";
import BASE_URL, { checkOutCart } from "../../utils/api";

const CartItemCounter = ({ item, itemId }) => {
  const { state, initCounter, increment, decrement } = useContext(AppContext);
  
  // Local state for the counter (this is where you'll manage item quantity)
  const [quantity, setQuantity] = useState(item.quantity); 

  // Initialize counter when the component mounts or when itemId changes
  useEffect(() => {
    initCounter(itemId);  // Initializes the counter
    setQuantity(item.quantity); // Syncs with the backend quantity on initial render
  }, [itemId]); // Only depend on itemId to avoid unnecessary updates

  // Function to update the quantity in the backend
  const fetchQuantityUpdate = async (newQuantity) => {
    try {
      // Send the updated quantity for the specific item
      await fetch(`${BASE_URL}cart/update-quantity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          quantity: newQuantity, 
          cart_id: item?.id // Send the entire item object if needed
        }),
      });
      
      // After updating the quantity, you can call the checkOutCart API
      await checkOutCart();
    } catch (error) {
      console.error("Failed to update quantity:", error);
    }
  };

  // Handlers for the buttons
  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);  // Update local quantity
    fetchQuantityUpdate(newQuantity); // Call API to update quantity
    increment(itemId); // Update local state in AppContext if needed
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);  // Update local quantity
      fetchQuantityUpdate(newQuantity); // Call API to update quantity
      decrement(itemId); // Update local state in AppContext if needed
    }
  };

  return (
    <div className="counter-container">
      <button className="counter-btn" onClick={handleDecrement}>
        −
      </button>
      <span className="counter-value">{quantity.toString().padStart(2, "0")}</span>
      <button className="counter-btn" onClick={handleIncrement}>
        +
      </button>
    </div>
  );
};

export default CartItemCounter;
