import React, { useState } from 'react';
import upload from '../../../../media/images/uploadicon.png';
import arrd from '../../../../media/images/arrowDown.png'
import { Link } from 'react-router-dom';

function AddProduct() {
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const [searchColor, setSearchColor] = useState('#ffffff');
  const [isToggled, setIsToggled] = useState(true);
  const [isToggled2, setIsToggled2] = useState(false);
  const [isToggled3, setIsToggled3] = useState(false);
  const [isToggled4, setIsToggled4] = useState(false);
  const [isToggled5, setIsToggled5] = useState(false);
  const [priceToggle, setPriceToggle] = useState(false);
  const [shippingToggle, setShippingToggle] = useState(false);


  const colors = ['#FF0000', '#8C746A', '#D8AA4B', '#94C897', '#7BADE4', '#FF0000'];

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);

    if (files.length !== 5) {
      setError('You must upload exactly 5 images.');
      return;
    }

    setError('');
    const newImages = files.map((file) => URL.createObjectURL(file));
    setImages(newImages);
  };

  const handlePriceDropdown = () => {
    setPriceToggle(!priceToggle)
  }
  const handleShippingDropdown = () => {
    setShippingToggle(!priceToggle)
  }

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleToggle2 = () => {
    setIsToggled2(!isToggled2);
  };
  const handleToggle3 = () => {
    setIsToggled3(!isToggled3);
  };
  const handleToggle4 = () => {
    setIsToggled4(!isToggled4);
  };
  const handleToggle5 = () => {
    setIsToggled5(!isToggled5);
  };


  return (
    <div className="add-product-main">
      <div className="product-images-upload-main">
        <div className="add-product-heading">
          <h2>Describe Your Product</h2>
        </div>

        <div className="upload-cover-images-here">
          <div className="upload-icon-text">
            <img src={upload} alt="Upload Icon" />
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageUpload}
            />
            <p>Upload Exactly 5 Images</p>
          </div>
          {error ? <p className="error-message">{error}</p> :
            <p className="suggestion-message">Add 5 images covering all angles of the item that describes well</p>}
        </div>

        {images.length > 0 && (
          <div className="image-preview">
            {images.map((image, index) => (
              <div key={index} className="image-wrapper">
                <img src={image} alt={`Preview ${index + 1}`} />
              </div>
            ))}
          </div>
        )}
      </div>


      {/* item specific */}
      <div className="product-form-main">
        <div className="product-form-item-heading">
          <h2>ITEM SPECIFICS</h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-product-name">
              <input type='text' placeholder='Product' />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="input-product-weight">
              <input type='text' placeholder='Weight' />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-product-height">
              <input type='text' placeholder='Height' />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-product-width">
              <input type='text' placeholder='Width' />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-product-brand">
              <input type='text' placeholder='Brand' />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-product-stock">
              <input type='text' placeholder='Stock Capacity' />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-product-category">
              <input type='text' placeholder='Category' />
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">
            <div className="available-colors-text">
              <input type='text' placeholder='Available Colors' disabled />
            </div>
          </div>
          <div className="col-md-7">
            <div className="colors-row-add-product">
              {colors.map((color, index) => (
                <div
                  key={index}
                  className="color-box-add-product"
                  style={{ backgroundColor: color }}
                ></div>
              ))}
              <input
                type="text"
                className="color-input-add-product"
                value={searchColor}
                onChange={(e) => setSearchColor(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-2">
            <span className="search-label-add-product">Search by code</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-product-category">
              <input type='text' placeholder='Sizes Available' />
            </div>
            <div className="size-available-add-product">
              <div className="row">
                <div className="col-lg-6">
                  <button>Small</button>
                </div>
                <div className="col-lg-6">
                  <button>Medium</button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <button>Large</button>
                </div>
                <div className="col-lg-6">
                  <button>Extra Large</button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-product-category">
              <input type='text' placeholder='Quantity' />
            </div>
            <div className="size-available-add-product">
              <div className="row">
                <div className="col-lg-6">
                  <button>85</button>
                </div>
                <div className="col-lg-6">
                  <button>25</button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <button>75</button>
                </div>
                <div className="col-lg-6">
                  <button>35</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="input-product-textarea">
            <textarea placeholder='Description' />
          </div>

        </div>
      </div>

      {/* picing */}
      <div className="product-pricing-form-main">
        <div className="pricing-form-heading">
          <h2>Pricings</h2>
        </div>

        <div className="add-service-set-price-input">
          <p>Set Price</p>
          <input placeholder='$45.00' />
        </div>


        <div className="add-service-Price-Dropdown">
          <div className="price-toggle-button">
            <button onClick={handlePriceDropdown} >Sale price</button>
            <img src={arrd} />
          </div>
          {priceToggle && <ul>
            <div className="fixed-price-toggle">
              <li>Hot Deals</li>
              <div className="toggle-price">
                <div className={isToggled ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle}>
                  <div className={`toggle-price-switch ${isToggled ? "on" : "off"}`}></div>
                </div>
              </div>
            </div>
            <div className="hourly-toggle">
              <li>Free Delivery</li>
              <div className="toggle-price">
                <div className={isToggled2 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle2}>
                  <div className={`toggle-price-switch ${isToggled2 ? "on" : "off"}`}></div>
                </div>
              </div>
            </div>
            <div className="hourly-toggle">
              <li>Discount</li>
              <div className="toggle-price">
                <div className={isToggled3 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle3}>
                  <div className={`toggle-price-switch ${isToggled3 ? "on" : "off"}`}></div>
                </div>
              </div>
            </div>
          </ul>}
        </div>

        <div className="add-service-set-price-input">
          <p>Packaging Price</p>
          <input placeholder='$10.00' />
        </div>

      </div>

      {/* shipping */}
      <div className="product-pricing-form-main">
        <div className="pricing-form-heading">
          <h2>Shipping</h2>
        </div>

        <div className="add-service-Price-Dropdown">
          <div className="price-toggle-button">
            <button onClick={handleShippingDropdown} >Deliver Domestically</button>
            <img src={arrd} />
          </div>
          {shippingToggle && <ul>
            <div className="fixed-price-toggle">
              <li>Deliver Domestically</li>
              <div className="toggle-price">
                <div className={isToggled4 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle4}>
                  <div className={`toggle-price-switch ${isToggled4 ? "on" : "off"}`}></div>
                </div>
              </div>
            </div>
            <div className="hourly-toggle">
              <li>Deliver Internationally</li>
              <div className="toggle-price">
                <div className={isToggled5 ? "toggle-price-toggle-switch" : "toggle-price-toggle-switch-off"} onClick={handleToggle5}>
                  <div className={`toggle-price-switch ${isToggled5 ? "on" : "off"}`}></div>
                </div>
              </div>
            </div>

          </ul>}
        </div>

      </div>

      {/* lead time */}
      <div className='add-product-main-lead-time'>
        <div className="lead-time-heading">
          <h3>Lead Time</h3>
        </div>
        <div className="row">
          <div className="lead-time-values">
            <div className="row">

              <div className="col-lg-6">
                <p>Quantity</p>
              </div>
              <div className="col-lg-6">
                <p>Lead Time</p>
              </div>
            </div>
          </div>
          <div className="lead-time-values-amswer">
            <div className="row">
              <div className="col-md-6">
                <button>1-100</button>
              </div>
              <div className="col-md-6">
                <button>03 Days</button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button>100-250</button>
              </div>
              <div className="col-md-6">
                <button>05 Days</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button */}
      <div className="seller-service-dashboard-main-right-button">
       <Link to="/preview-detail-product"> <button style={{ width: "100%" }} >Preview</button></Link>
      </div>
    </div>
  );
}

export default AddProduct;
